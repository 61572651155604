import styled from "styled-components"

export const Wrapper = styled.div`
  align-items: center;
  cursor: s-resize;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.h1`
  font-size: 8vw;
  left: 50%;
  opacity: 0.9;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`
