const React = require("react")
const Layout = require("./src/components/Layout").default
const { Store } = require("./src/store")

exports.wrapRootElement = ({ element }) => {
  return <Store>{element}</Store>
}

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
