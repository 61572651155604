import styled, { createGlobalStyle } from 'styled-components';

import { typography, mediaQueries } from '../../theme';

export const GlobalStyle = createGlobalStyle`
    @keyframes rotate {
        0% { transform: rotateZ(0deg); }
        100% { transform: rotateZ(360deg); }
    }

    html {
        background: ${props => props.theme.background};
        color: ${props => props.theme.color};
        font-family: ${typography.FONT_FAMILY};
        font-size: ${typography.FONT_SIZE__PX}px;
        /* transition: background .5s ease-in-out, color .5s ease-in-out; */
        margin: 0;
        padding: 0;
    }

    body {
        background: transparent;
        color: inherit;
        font-size: ${typography.FONT_SIZE__REM}rem;
        line-height: ${typography.LINE_HEIGHT__REM}rem;
        margin: 0;
        padding: 0;
    }

    *, *:before, *:after {
        box-sizing: border-box;
        color: ${props => props.theme.color};
        /* transition: color .5s ease-in-out; */
        -webkit-font-smoothing: antialiased;
    }

    a {
        color: ${props => props.theme.color};
        /* transition: color .5s ease-in-out; */
        text-decoration: none;
    }

    p, h1, h2, h3, h4, h5, h6, ul, ol {
        font-size: inherit;
        font-weight: normal;
        line-height: inherit;
        margin: ${typography.LINE_HEIGHT__REM}rem 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Wrapper = styled.main``;

export const Content = styled.div`
  min-height: 100vh;
  padding: 60px calc(100px + ${typography.LINE_HEIGHT__SMALL__REM}rem);

  ${mediaQueries.TINY} {
    padding: calc(20px + ${typography.LINE_HEIGHT__SMALL__REM}rem) 15px 60px;
  }
`;

export const Footer = styled.footer`
  font-size: ${typography.FONT_SIZE__SMALL__REM}rem;
  line-height: ${typography.LINE_HEIGHT__SMALL__REM}rem;
  text-align: right;
`;
