import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import { animateScroll } from 'react-scroll';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import useScrollTop from '../../hooks/useScrollTop';
import { GlobalState } from '../../store';

import { Wrapper, Title } from './style';

const Intro = () => {
  const globalStateDispatch = useContext(GlobalState.Dispatch);
  const { introIsHidden } = useContext(GlobalState.State);
  const [isAnimating, setIsAnimating] = useState(false);
  const { height } = useWindowDimensions();
  const scrollTop = useScrollTop();

  // const opacity = useMemo(
  //   () => (scrollTop && height ? 1 - scrollTop / (height / 1.5) : 1),
  //   [height, scrollTop]
  // );
  const translateY = useMemo(() => (scrollTop / 1.5) * -1, [scrollTop]);

  const duration = 800;
  const scrollDown = useCallback(
    evt => {
      setIsAnimating(true);
      animateScroll.scrollTo(height, { duration });
      setTimeout(() => {
        setIsAnimating(false);
      }, duration);
    },
    [height]
  );

  useEffect(() => {
    if (height === 0 || isAnimating || introIsHidden) return;
    if (scrollTop >= height) {
      globalStateDispatch({ type: 'setIntroIsHidden', introIsHidden: true });
      animateScroll.scrollTo(0, { duration: 0 });
    }
  }, [isAnimating, introIsHidden, height, scrollTop, globalStateDispatch]);

  return introIsHidden ? null : (
    <Wrapper style={{ height }} onClick={scrollDown}>
      <Title
        style={{
          transform: `translate3d(-50%, calc(-50% + ${translateY}px), 0)`,
        }}
      >
        Side Note
      </Title>
    </Wrapper>
  );
};

export default Intro;
