import { useState, useEffect, useCallback } from 'react';
import { breakpoints } from '../theme';

export default () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  const updateWindowDimensions = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    let breakpoint = 'TINY';
    if (width > breakpoints.LAPTOP_MAX) {
      breakpoint = 'DESKTOP';
    } else if (width > breakpoints.COMPACT_MAX) {
      breakpoint = 'LAPTOP';
    } else if (width > breakpoints.MOBILE_MAX) {
      breakpoint = 'COMPACT';
    } else if (width > breakpoints.TINY_MAX) {
      breakpoint = 'MOBILE';
    }

    setWindowDimensions({
      width,
      height,
      breakpoint,
    });
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  return windowDimensions;
};
