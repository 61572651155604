export const TINY_MAX = 580

export const MOBILE_MIN = TINY_MAX + 1
export const MOBILE_MAX = 780

export const COMPACT_MIN = MOBILE_MAX + 1
export const COMPACT_MAX = 1000

export const LAPTOP_MIN = COMPACT_MAX + 1
export const LAPTOP_MAX = 1600

export const DESKTOP_MIN = LAPTOP_MAX + 1

export const NAMES = ["TINY", "MOBILE", "COMPACT", "LAPTOP", "DESKTOP"]
