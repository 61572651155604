import React, { useContext, useEffect } from 'react';
import { GlobalState } from '../../store';

import Header from '../Header';

import { GlobalStyle, Wrapper, Content, Footer } from './style';
import Intro from '../Intro';

import './fonts.css';

const Layout = ({ children, location }) => {
  const { theme } = useContext(GlobalState.State);
  const globalStateDispatch = useContext(GlobalState.Dispatch);

  useEffect(() => {
    window.addEventListener('touchstart', () => {
      globalStateDispatch({ type: 'setDeviceHasTouchEvents' });
    });
  }, [globalStateDispatch]);

  return (
    <Wrapper>
      <GlobalStyle theme={theme} />
      <Intro />
      <Header location={location} theme={theme} />
      <Content>
        {children}
        <Footer>&copy;{new Date().getFullYear()} Side Note Magazine</Footer>
      </Content>
    </Wrapper>
  );
};

export default Layout;
