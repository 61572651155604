import * as breakpoints from "./breakpoints"

export const TINY = `@media screen and (max-width: ${breakpoints.TINY_MAX}px)`

export const MOBILE = `@media screen and (max-width: ${breakpoints.MOBILE_MAX}px)`

export const COMPACT = `@media screen and (max-width: ${breakpoints.COMPACT_MAX}px)`

export const LAPTOP = `@media screen and (max-width: ${breakpoints.LAPTOP_MAX}px)`

export const DESKTOP = `@media screen and (min-width: ${breakpoints.DESKTOP_MIN}px)`
