import React, { createContext, useReducer } from 'react';

const initialState = {
  deviceHasTouchEvents: false,
  activePostId: null,
  introIsHidden: false,
  theme: {
    color: '#FFF',
    background: '#000',
  },
};

// Context
const State = createContext(initialState);
const Dispatch = createContext();

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case 'setActivePostId':
      return {
        ...state,
        activePostId: action.activePostId,
      };
    case 'setIntroIsHidden':
      return {
        ...state,
        introIsHidden: action.introIsHidden,
      };
    case 'setTheme':
      return {
        ...state,
        theme: action.theme,
      };
    case 'setDeviceHasTouchEvents':
      return {
        ...state,
        deviceHasTouchEvents: true,
      };
    default:
      return state;
  }
};

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  );
};

export const GlobalState = {
  State,
  Dispatch,
  Provider,
};
