import React, { useContext } from 'react';

import { GlobalState } from '../../store';

import { Wrapper, Left, Right, Link } from './style';

const Header = ({ theme }) => {
  const { introIsHidden } = useContext(GlobalState.State);

  return (
    <Wrapper isHidden={!introIsHidden} theme={theme}>
      <Left isHidden={!introIsHidden}>
        <Link to="/" activeClassName="active">
          Side Note
        </Link>
      </Left>
      <Right isHidden={!introIsHidden}>
        <Link to="/about" activeClassName="active">
          About
        </Link>
        <span> & </span>
        <Link to="/events" activeClassName="active">
          Events
        </Link>
      </Right>
    </Wrapper>
  );
};

export default Header;
