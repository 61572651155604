import React, { createContext, useReducer } from "react"

const initialState = {
  activeCategory: null,
}

// Context
const State = createContext(initialState)
const Dispatch = createContext()

// Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "setActiveCategory":
      return {
        ...state,
        activeCategory: action.activeCategory,
      }
    default:
      return state
  }
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

export const ThumbnailsState = {
  State,
  Dispatch,
  Provider,
}
