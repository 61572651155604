export const FONT_FAMILY = 'Maria2';

export const FONT_SIZE__PX = 24;
export const FONT_SIZE__SMALL__PX = 20;
export const FONT_SIZE__XSMALL__PX = 16;

export const LINE_HEIGHT__PX = 29;
export const LINE_HEIGHT__SMALL__PX = 24;
export const LINE_HEIGHT__XSMALL__PX = 20;

export const REM = FONT_SIZE__PX;

export const FONT_SIZE__REM = FONT_SIZE__PX / REM;
export const FONT_SIZE__SMALL__REM = FONT_SIZE__SMALL__PX / REM;
export const FONT_SIZE__XSMALL__REM = FONT_SIZE__XSMALL__PX / REM;

export const LINE_HEIGHT__REM = LINE_HEIGHT__PX / REM;
export const LINE_HEIGHT__SMALL__REM = LINE_HEIGHT__SMALL__PX / REM;
export const LINE_HEIGHT__XSMALL__REM = LINE_HEIGHT__XSMALL__PX / REM;
