import { useState, useEffect, useCallback } from 'react';

export default () => {
  const [scrollTop, setScrollTop] = useState(0);

  const updateScrollTop = useCallback(() => {
    setScrollTop(document.documentElement.scrollTop || document.body.scrollTop);
  }, []);

  useEffect(() => {
    updateScrollTop();
    window.addEventListener('scroll', updateScrollTop);
    return () => {
      window.removeEventListener('scroll', updateScrollTop);
    };
  }, [updateScrollTop]);

  return scrollTop;
};
