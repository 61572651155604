import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { typography, mediaQueries } from '../../theme';

export const Link = styled(GatsbyLink)`
  display: inline-block;
  pointer-events: auto;
  position: relative;
  text-decoration: none;

  &:after {
    border-bottom: 2px solid transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
  }
`;

export const Wrapper = styled.header`
  bottom: 0;
  font-size: ${typography.FONT_SIZE__SMALL__REM}rem;
  left: 0;
  line-height: ${typography.LINE_HEIGHT__SMALL__REM}rem;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  ${mediaQueries.TINY} {
    background: ${props => props.theme.background};
    bottom: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    transform: translate3d(0, ${props => (props.isHidden ? '-100%' : '0')}, 0);
    transition: transform 0.4s ease-in-out;
  }

  ${Link} {
    &.active::after {
      border-bottom-color: ${props => props.theme.color};
    }
  }
`;

export const Left = styled.nav`
  left: -50vh;
  padding: 50px;
  position: absolute;
  text-align: center;
  top: calc(calc(100px + ${typography.LINE_HEIGHT__SMALL__REM}rem) * -0.5);
  transform: rotate(-90deg)
    translate3d(-50%, ${props => (props.isHidden ? '-50%' : '50%')}, 0);
  transition: transform 0.4s ease-in-out;
  width: 100vh;

  ${mediaQueries.TINY} {
    left: auto;
    padding: 0;
    position: relative;
    text-align: left;
    top: auto;
    transform: none;
    width: auto;
  }
`;

export const Right = styled(Left)`
  left: auto;
  right: -50vh;
  transform: rotate(-90deg)
    translate3d(-50%, ${props => (props.isHidden ? '50%' : '-50%')}, 0);

  ${mediaQueries.TINY} {
    right: 0;
    padding: 0;
    position: relative;
    text-align: right;
    top: auto;
    transform: none;
    width: auto;
  }
`;
