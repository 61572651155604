import React from "react"
import { GlobalState } from "./GlobalState"
import { ThumbnailsState } from "./ThumbnailsState"

const providers = [<GlobalState.Provider />, <ThumbnailsState.Provider />]

const Store = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => React.cloneElement(parent, { children }),
    initial
  )

export { Store, GlobalState, ThumbnailsState }
